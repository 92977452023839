<template>
	<section id="portfolio" class="size-18">
		<b-row>
		    <b-col md="12">
		    	<h2 class="mb-2 pb-1">Our Portfolio</h2>
		    </b-col>
		</b-row>
		<b-row>
		    <b-col md="12" id="portfolio-tab">
		    	<template v-if="userData && userData.isExperProject">
		    		<template v-for="(item, index) in tabsFull">
			    		<router-link :to="item.link" :key="item.index">
					    	<b-card class="d-flex mb-20">
					    		{{ item.name }}
					    		<feather-icon
						            icon="ChevronRightIcon"
						            size="24"
						            color="#2E3A59"
						          />
					    	</b-card>
					    </router-link>
			    	</template>
		    	</template>
		    	<template v-else>
		    		<template v-for="(item, index) in tabs">
			    		<router-link :to="item.link" :key="item.index">
					    	<b-card class="d-flex mb-20">
					    		{{ item.name }}
					    		<feather-icon
						            icon="ChevronRightIcon"
						            size="24"
						            color="#2E3A59"
						          />
					    	</b-card>
					    </router-link>
			    	</template>
		    	</template>
		    </b-col>
		</b-row>
	</section>	 
</template>

<script>
	export default {
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
		    	tabsFull: [
		    		{
			            name: 'Project Listing',
			            link: '/expert/our-portfolio/project'
			        },
			        {
			            name: 'Activity Listing',
			            link: '/expert/our-portfolio/activity-listing'
			        },	
		    	],
		      	tabs: [
		          	{
			            name: 'Activity Listing',
			            link: '/expert/our-portfolio/activity-listing'
		          	},	          
		        ]
		    }
		}
	};	
	
</script>

<style lang="scss">
	#portfolio-tab .card .card-body{
		display: flex; 
		align-items: center; 
		justify-content: space-between;
	}
	#portfolio-tab a{
		color: #000000; 
	}
	#portfolio-tab a:hover {
	    color: #8471FF;
	}
</style>

